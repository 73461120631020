import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';
import Lenis from '@studio-freight/lenis';

// Register GSAP plugins outside of any load event listeners
gsap.registerPlugin(ScrollTrigger);

// Use window.onload to ensure everything is loaded
window.onload = function() {
    // Use setTimeout to delay the execution
    setTimeout(function() {

        const splitTypes = document.querySelectorAll(".reveal-type");

        splitTypes.forEach((char, i) => {
            const bg = char.dataset.bgColor;
            const fg = char.dataset.fgColor;

            // Ensure that SplitType is successfully initializing
            const text = new SplitType(char, { types: "chars" });

            // Apply GSAP animations
            gsap.fromTo(
                text.chars,
                { color: bg },
                {
                    color: fg,
                    duration: 0.3,
                    stagger: 0.02,
                    scrollTrigger: {
                        trigger: char,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,
                        markers: false,
                        toggleActions: "play play reverse reverse"
                    }
                }
            );
        });

        // Initialize and configure Lenis
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);

    }, 7000);
};
